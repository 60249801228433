@import "assets/styles/abstracts";

.partners-item-detail {
  &__logo {
    width: rem(60);
    height: rem(60);
    border-radius: 100%;
    object-fit: cover;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: rem(20);
  }

  &__title {
    margin-left: rem(20);
  }

  &__name {
    font-weight: 500;
    font-size: rem(20);
    line-height: rem(24);
    font-family: $font-bold;
    margin-bottom: rem(10);
  }

  &__body {
    font-size: rem(16);
    line-height: rem(22);
    margin-bottom: rem(20);
  }
}