@import "assets/styles/abstracts";

.ant-slider-handle {
  border: 5px solid $main !important;

  &:focus {
    box-shadow: 0 0 0 5px #DFE4D9 !important;
  }
}

.ant-slider-track {
  height: 2px!important;
  background-color: $main!important;
}
.ant-slider-rail {
  height: 2px!important;
}

.input {
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  border-radius: 4px;
  outline: none;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  height: 45px;
  &.error {
    border-color: red;
  }
}

.checkbox {
  &.error {
    background: red;
    box-shadow: 0px 0px 5px 0px red;
  }
}

.label {
  font-size: rem(14);
  line-height: rem(17);
  color: $army;
  margin-bottom: rem(7);
  display: inline-block;
}
