@import "assets/styles/abstracts";

.home-loan-input {
  margin-right: 10px;
  color: $army;
  width: 100%;
  margin-bottom: 20px;
  &__content {
    position: relative;

  }

  &__label {
    font-weight: 500;
    font-size: rem(16);
    line-height: rem(19);
    color: $army;
    margin-bottom: 6px;
    display: inline-block;
  }

  .ant-slider {
    position: absolute;
    bottom: -5px;
    width: 100%;
    margin: 0;
  }

  &__measure {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: rem(40);
    font-size: rem(16);
    font-family: $font-bold;
  }

}
