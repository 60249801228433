

@font-face {
  font-family: "West Regular";
  src: url("../../fonts/WestTrial-Regular.otf");
}

@font-face {
  font-family: "West Medium";
  src: url("../../fonts/WestTrial-Medium.otf");
}

@font-face {
  font-family: "West Bold";
  src: url("../../fonts/WestTrial-Bold.otf");
}

@font-face {
  font-family: "West Light";
  src: url("../../fonts/WestTrial-Light.otf");
}


@font-face {
  font-family: "Montserrat Regular";
  src: url("../../fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Medium";
  src: url("../../fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("../../fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat Light";
  src: url("../../fonts/Montserrat-Light.ttf");
}

