@import "assets/styles/abstracts";

.home-about {
  &__more {
    margin-top: rem(50);
    display: inline-block;
    font-size: rem(20);
    font-family: $font;
    color: $black;
    @include transition();
    position: relative;
    &:before {
      content: "";
      position: absolute;
      bottom: -7px;
      left: 0;
      height: 4px;
      width: 100%;
      @include transition();
      background-color: $main;
    }
    &:hover {
      color: $main;
      &:before {
        width: 10%;
      }
    }
  }
  &__image {
    height: 543px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
@include breakpoint($lg) {
  .home-about {
    &__image {
      height: 360px;
      margin-top: 30px;
    }
  }
}
