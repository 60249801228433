@import "assets/styles/abstracts";

.about-purpose-content {
  display: flex;
  align-items: center;
  margin-bottom: rem(60);

  &:nth-child(even) {
    margin-left: rem(60);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__image {
    background-color: white;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(120);
    height: rem(120);
    flex: 0 0 auto;
    margin-right: rem(20);
  }

  &__right {
    flex: 0 0 56%;

  }

  &__title {
    font-weight: 500;
    font-family: $font-bold;
    font-size: rem(20);
    line-height: rem(26);
  }

  &__text {
    font-weight: 400;
    font-size: rem(16);
    line-height: rem(22);
  }
}

@include breakpoint($lg) {
  .about-purpose-content {
    align-items: start;
    margin-bottom: 30px;

    &:nth-child(even) {
      margin-left: 0;
    }

    &__right {
      margin-top: 10px;
    }
  }
}
