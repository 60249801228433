@import "assets/styles/abstracts";

.header {
  padding: rem(30) 0;
  font-family: $font2;
  position: sticky;
  top: 0;
  z-index: 10;
  @include transition(all, .1s);

  &--green {
    background-color: $lead-bg;
  }

  &--sticky {
    box-shadow: 0 2px 4px rgb(62 111 0 / 14%);
    ;
    background-color: white;
  }

  &__toggle {
    width: 30px;
    cursor: pointer;
    margin-left: auto;
    position: relative;
    z-index: 1000;

    span {
      display: block;
      width: 30px;
      height: 2px;
      background-color: $main;
      margin: 5px 0;
    }

    &--open {
      span {
        // @include transition;
        &:first-child {
          animation: toggleMenu1 0.1s;
          animation-fill-mode: forwards;
        }
       
        &:nth-child(2) {
          display: none;
        }
        &:last-child {
          animation: toggleMenu2 0.1s;
          animation-fill-mode: forwards;
        }
      }
    }
  }
}

@include breakpoint($lg) {
  .header {
    &__menu {
      position: fixed;
      z-index: 1000;
      left: 0;
      top: 0;
      background: white;
      height: 100%;
      width: 100%;
    }
  }
}

@keyframes toggleMenu1 {
  0% {
    transform: rotate(0);

  }

  100% {
    transform: translate(-3px,-3px) rotate(45deg)
  }
}

@keyframes toggleMenu2 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: translate(-3px,-9px) rotate(-45deg)
  }
}
