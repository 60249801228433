@import "assets/styles/abstracts";

.about-strategy-card {
  padding-bottom: rem(20);
  border-bottom: 1px solid rgba(62, 111, 0, 0.1);;
  &__image {
    width: rem(90);
    height: rem(90);
    border-radius: 100%;
    background-color: $bg;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(30);
  }
  &__title {
    font-weight: 800;
    font-size: rem(20);
    line-height: rem(26);
    font-family: $font-bold;
    color: $main;
    margin-bottom: rem(16);
  }

  &__text {
    font-size: rem(16);
    line-height: rem(24);
    color: $text-gray;
  }
}
