@import "assets/styles/abstracts";

.footer-left {
  &__logo {
    margin-bottom: rem(24);
  }

  &__desc {
    font-size: rem(16);
    line-height: rem(24);
    margin-bottom: rem(24);
  }

  &__socials {
    display: flex;

    &-item {
      margin: 0 7px;
    }

    &-link {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}


@include breakpoint($lg) {
  .footer-left {
    margin-bottom: 40px;
  }
}
