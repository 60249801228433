@import "assets/styles/abstracts";
.contact-discover {
  margin-top: rem(80);
  &__title {
    margin-bottom: rem(40);
  }
  &__item {
    margin-bottom: rem(40);
    &-heading {
      font-weight: 800;
      font-size: rem(32);
      font-family: $font-bold;
      margin-bottom: rem(10);
    }
    &-text {
      font-size: rem(22);
      text-decoration-line: underline;
    }
  }
}
