@import "assets/styles/abstracts";

.home-video {
  &__cover {
    margin-bottom: rem(32);
    position: relative;
  }

  &__media {
    width: 100%;
    max-height: rem(578);
    object-fit: cover;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: rem(22);
  }

  &__player {
    position: absolute;
    left: 20px;
    bottom: 20px;
    cursor: pointer;
  }
}
@include breakpoint($lg) {
  .home-video {
    &__player {
      width: 50px;
      left: 5px;
      bottom: 5px;
    }
  }
}
