@import "assets/styles/abstracts";

.footer {
  background-color: $bg;
  padding: rem(120) rem(0) rem(24);
}

@include breakpoint($lg) {
  .footer {
    padding-top: 20px;
  }
}
