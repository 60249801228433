@import "assets/styles/abstracts";

.menu {
  display: flex;
  text-align: right;
  justify-content: right;

  &__item {
    margin: 0 15px;
  }

  &__link {
    display: inline-block;
    color: $black;
    font-weight: 500;
    font-size: rem(16);
    @include transition();
    position: relative;
    &::before {
      content: "";
      width: 5px;
      height: 5px;
      background-color: $main;
      border-radius: 100%;
      display: inline-block;
      position: absolute;
      left: -15px;
      top: calc(50% - 2.5px);
      @include transition();
      opacity: 0;
      pointer-events: none;
    }
    &.active {
      font-family: $font-bold;
      color: $main;
      pointer-events: none;
      &::before {
        opacity: 1;
        left: -10px;
      }
    }
    &:hover {
      color: $main;
      &::before {
        opacity: 1;
        left: -10px;
      }
    }
  }
}

@include breakpoint($lg) {
  .menu {
    display: block;
    text-align: center;
    margin-top: rem(60);
    &__item {
      margin-bottom: rem(20);
    }
    &__link {
      font-size: rem(30);
    }
  }
}