@import "assets/styles/abstracts";

.footer-right {
  display: flex;
  justify-content: end;

  &__content {
    &:last-of-type {
      margin-left: rem(150);
    }
  }

  &__title {
    font-weight: 700;
    font-family: $font-bold;
    font-size: rem(20);
    line-height: rem(22);
    margin-bottom: rem(40);
  }

  &__list {
    font-size: rem(18);
    line-height: rem(20);
  }

  &__item {
    margin-bottom: 20px;
  }

  &__link {
    color: $black;
  }
  &__icon {
    margin-right: rem(10);
  }
}



@include breakpoint($lg) {
  .footer-right {
    justify-content: start;
  }
}
