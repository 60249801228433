@import "assets/styles/abstracts";

.about-photo {
  margin-bottom: rem(100);
  &__content {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 20%;
      left: 0;
      background-color: $bg;
      display: inline-block;
      height: 90%;
      width: 100%;
      z-index: -1;
    }
  }
}
