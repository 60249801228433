@import "assets/styles/abstracts";

.btn {
  padding: rem(14) rem(24);
  cursor: pointer;
  border-radius: 8px;
  background-color: white;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &--main {
    background-color: $main;
    color: white;
    &:hover {
      color: white;
    }
  }
  &--outline {
    border: 1px solid $main;
    color: $main;
    &:hover {
      color: $main;
    }
    svg {
      path{
        fill: $main;
      }
    }
  }
  svg {
    margin-left: 10px;
    &.arrow-down {
      transform: rotate(90deg);
    }
  }

}
