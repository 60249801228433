@import "assets/styles/abstracts";

.section-bg {
  position: relative;
  padding: rem(76) 0;
  &::before {
    background-color: $bg;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 80%;
    height: 100%;
    z-index: -1;
  }
}
@include breakpoint($lg) {
  .section-bg {
    padding: 16px;
    &::before {
      width: 100%;
    }
  }
}
