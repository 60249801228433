@import "assets/styles/abstracts";

.product-details-card {
  border-bottom: 1px solid $bg;
  padding-bottom: rem(20);
  margin-bottom: rem(35);
  &__image {
    display: inline-block;
    padding: rem(23);
    border-radius: 100%;
    background-color: $bg;
    margin-bottom: rem(30);
    img {
      width: rem(44);
      height: rem(44);
      object-fit: cover;
    }
  }
  &__title {
    font-weight: 800;
    font-size: rem(20);
    font-family: $font-bold;
    color: $main;
    margin-bottom: rem(16);
  }
  &__amount {
    font-weight: 500;
    font-size: rem(18);
    color: $text-gray;
  }
}
