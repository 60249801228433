@import "assets/styles/abstracts";

.home-loan-calc {
  padding-right: rem(100);
  &__inputs {
    margin-bottom: rem(40);
    display: flex;
  }

  &__result {
    margin-bottom: rem(40);
    &-label {
      font-weight: 400;
      font-size: 12px;
      line-height: rem(15);
      color: $army;
      margin-bottom: rem(5);
    }
    &-content {
      font-weight: 500;
      font-size: rem(18);
      line-height: rem(22);
      color: $main;
    }
  }

  &__agreement {
    input {
      margin-right: 10px;
    }
    .label {
      font-size: rem(16);
    }
  }
}

@include breakpoint($lg) {
  .home-loan-calc {
    margin-top: 30px;
    padding: 0;
    &__inputs {
      display: block;
    }
  }
}
