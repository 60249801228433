@import "assets/styles/abstracts";

.section-heading {
  &__title {
    font-weight: 800;
    font-size: rem(16);
    line-height: rem(21);
    color: $main;
    font-family: $font-bold;

  }

  &__desc {
    font-weight: 800;
    font-size: rem(44);
    line-height: rem(58);
    color: $black;
    font-family: $font-bold;

    &--green {
      color: $main;
    }

  }

  &__text {
    font-weight: 400;
    font-size: rem(16);
    line-height: rem(22);
  }
}

@include breakpoint($md) {
  .section-heading {

    &__desc {
      font-size: 28px;
      line-height: 30px;
      margin-bottom: 10px;
    }

  }
}
