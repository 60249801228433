@import "assets/styles/abstracts";

.modal {
  background: #00000030;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  &__content {
    background: #F7F9F5;
    box-shadow: $shadow;
    border-radius: 16px;
    padding: rem(32);
    max-width: calc(100% - 50px);
  }
}
