@import "assets/styles/abstracts";

.product-details-lead {
  background-color: $lead-bg;
  padding-top: rem(100);
  padding-bottom: rem(80);

  &__photo {
    text-align: right;
  }
}

@include breakpoint($lg) {
  .product-details-lead {
    &__photo {
      margin-top: 40px;
    }
  }
}
