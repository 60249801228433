@import "assets/styles/abstracts";

.contact-images {
  margin-top: rem(40);

  &__item {
    width: rem(228);
    height: rem(291);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 4px solid $main;
    }

    &:last-of-type {
      margin-left: rem(45);
      margin-top: rem(70);
    }
  }

  &__content {
    display: flex;
    justify-content: end;

    &--second {
      transform: translate(rem(-102), rem(-180));
    }
  }
}
@include breakpoint($lg) {
  .contact-images {
    &__content {
      justify-content: center;
    }
    &__item {
      width: 180px;
      height: 210px;
    }
  }
}

@include breakpoint($md) {
  .contact-images {
    &__content {
      justify-content: end;
    }
    &__item {
      width: 120px;
      height: 170px;
    }
  }
}
