@import "assets/styles/abstracts";

.product-details-about {
  padding: rem(100) 0;

  &__text {
    font-size: rem(16);
    line-height: rem(27);
  }
}
