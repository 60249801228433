@import "assets/styles/abstracts";

.lead {
  margin: rem(30) 0 rem(50);
  &__heading {
    font-weight: 800;
    font-size: rem(72);
    line-height: rem(80);
    font-family: $font-bold;
    color: $main;
    &--sm {
      font-size: rem(55);
    }
  }
  &__text {
    margin-bottom: rem(24);
    font-weight: 400;
    font-size: 16px;
    line-height: rem(22);
  }
  &__buttons {
    display: flex;
    .btn:first-of-type {
      margin-right: 24px;
    }
  }
}
@include breakpoint($xl) {
  .lead {
    &__heading {
     font-size: 40px;
     line-height: 60px;
     margin-bottom: 15px;
    }
  }
}
@include breakpoint($lg) {
  .lead {
    &__heading {
     font-size: 33px;
     line-height: 45px;
     margin-bottom: 15px;
    }
    &__buttons {
      .btn {
        width: 100%;
      }
    }
  }
}
