@import "assets/styles/abstracts";

.home-bg {
  position: relative;
  &::before {
    background-color: $bg;
    content: "";
    position: absolute;
    left: 0;
    top: 10%;
    width: 100%;
    height: 80%;
    z-index: -1;
  }
}
