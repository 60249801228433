@import "assets/styles/abstracts";

.partners-item {
  border: 1px solid $bg;
  box-shadow: $shadow;
  border-radius: rem(16);
  padding: rem(20);
  cursor: pointer;
  margin-bottom: rem(30);
  &__head {
    display: flex;
    align-items: center;
    margin-bottom: rem(40);
  }
  &__logo {
    width: rem(32);
    height: rem(32);
    border-radius: 100%;
    object-fit: cover;
    margin-right: 15px;
  }

  &__name {
    font-weight: 500;
    font-size: rem(16);
    line-height: rem(19);
    font-family: $font-bold;
  }

  &__type {
    font-weight: 500;
    font-size: 9px;
    font-family: $font-bold;
    color: $main;
    background: rgba(62, 111, 0, 0.08);
    border-radius: 4px;
    display: inline-block;
    padding: 4px 7px;
  }
}
