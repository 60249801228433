//colors

$main: #3E6F00;
$black: #414D44;
$army: #404D43;
$white: #fff;
$gray: #9EA39F;
$body: #eaeaea;
$text: #757575;
$light-gray: #6F6C90;
$text-gray: #707872;
$lead-bg: #FAFFF3;

$bg: rgba(62, 111, 0, 0.04);
$shadow: 0px 0px 4px rgba(62, 111, 0, 0.04);

//sizes
$border: 4px;
$border-lg: 8px;
//fonts

$font: "West Regular";
$font2: "West Medium";

$font-bold: "West Bold";
$font-light: "West Light";
//
//$font: "Montserrat Medium";
//$font2: "Montserrat Medium";
//
//$font-bold: "Montserrat Bold";
//$font-light: "Montserrat Regular";

//breakpoints

$sm: 576px;
$md: 768px;
$lg: 991px;
$xl: 1200px;
