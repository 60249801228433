@import "assets/styles/abstracts";

.products-item {
  background-color: $bg;
  background-repeat: no-repeat;
  background-position: right;
  margin-bottom: rem(40);
  padding: rem(40);
  background-size: contain;
  &__name {
    font-weight: 800;
    font-size: rem(24);
    color: $main;
    font-family: $font-bold;
    margin-bottom: rem(10);
  }
  &__desc {
    font-size: rem(16);
    margin-bottom: rem(40);
  }
  &__counts {
    display: flex;
    margin-bottom: rem(40);
    &-item {
      margin-right: rem(55);
      font-size: rem(16);
    }
  }
  &__label {
    margin-bottom: rem(10);
  }
  &__number {
    font-weight: 500;
    font-family: $font-bold;
  }

  &__actions {
    display: flex;
    .btn {
      margin-right: rem(20);
      strong {
        font-family: $font-bold;
      }
    }
  }
}

@include breakpoint($xl) {
  .products-item {
    background-position: bottom right;
    background-size: 250px;
  }
}
@include breakpoint($lg) {
  .products-item {
    background-position: bottom right;
    background-size: 170px;
  }
}
@include breakpoint($md) {
  .products-item {
    background-position: bottom right;
    background-size: 100px;
  }
}

