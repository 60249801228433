@import "assets/styles/abstracts";

.footer-bottom {

  &__copyright {
    color: $light-gray;
    font-size: rem(15);
    line-height: rem(30);
  }
  &__rights {
    color: $light-gray;
    font-size: rem(15);
    line-height: rem(30);
    display: flex;
    justify-content: end;
    &-item {
      margin: 0 10px;
      a {
        color: $main;
        text-decoration: underline;
      }
    }
  }
}
